import React, { useCallback } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import OperatorPage from 'js/components/OperatorPage/OperatorPage';

import locationSections from './locationSections';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { useCurrentNode } from 'ecto-common/lib/hooks/useCurrentNode';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import { useStore } from 'zustand';
import { nodeTreeStore } from 'ecto-common/lib/LocationTreeView/NodeTreeStore';

const Location = () => {
  const params = useParams<NodeParams>();
  const { page } = params;
  const { currentNode: selectedNode } = useCurrentNode();
  const item = _.find(locationSections, { route: page });
  const rootLevelNodes = useStore(
    nodeTreeStore,
    (store) => store.rootLevelNodes
  );

  const noLocations = rootLevelNodes.length === 0;

  const onTitleChanged = useCallback((title: string[]) => {
    document.title = title.join(' | ');
  }, []);

  return (
    <OperatorPage
      content={
        <>
          {item &&
            !noLocations &&
            item.renderContent({ onTitleChanged, selectedNode })}

          {noLocations && (
            <ToolbarContentPage
              title={T.location.error.find}
              wrapContent={noLocations}
            >
              {T.location.error.nolocations}
            </ToolbarContentPage>
          )}
        </>
      }
    />
  );
};

export default Location;
