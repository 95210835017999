import React, { useContext, useMemo } from 'react';
import moment from 'moment';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';

import APIGen, {
  ActivityLogResponseModel,
  ActivityLogType,
  GridType,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';

import PagingFooter from 'ecto-common/lib/PagingFooter/PagingFooter';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import { SortDirectionType } from 'ecto-common/lib/DataTable/SortDirection';
import { ActivityLogTypeTranslations } from 'js/containers/ActivityLogTypeTranslations';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import Icons from 'ecto-common/lib/Icons/Icons';
import Flex, { FlexItem } from 'ecto-common/lib/Layout/Flex';
import {
  TimeFormats,
  getDefaultDateTimeFormat
} from 'ecto-common/lib/utils/dateUtils';
import { keepPreviousData } from '@tanstack/react-query';
import _ from 'lodash';
import { useNodesEx } from 'ecto-common/lib/hooks/useCurrentNode';
import { getSignalsUrl } from 'js/utils/routeConstants';
import { getFullPathNameV2 } from 'ecto-common/lib/utils/locationUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

interface LogViewProps {
  className?: string;
  nodeId: string;
  tableClassName?: string;
  activityLogTypes?: ActivityLogType[];
  onSortChange?: (orderBy: string, sortDirection: string) => void;
  currentPage: number;
  orderBy: string;
  sortDirection: SortDirectionType;
  searchString?: string;
  selectedSignalIds?: string[];
  onPageChange?: (newPage: number) => void;
}

const formatValue = (
  oldValue: number,
  row: ActivityLogResponseModel,
  units: Record<string, UnitResponseModel>
) => {
  if (oldValue == null || row.newValue == null) {
    return '';
  }

  const unit = units[row.unitId]?.unit;
  const oldValueString = formatNumberUnit(oldValue, unit);
  const newValueString = formatNumberUnit(row.newValue, unit);
  // Force width on the icon so the spacing is correct
  return (
    <Flex>
      <FlexItem>{oldValueString}</FlexItem>
      <FlexItem style={{ width: '14px' }}>
        <Icons.ArrowRight />
      </FlexItem>
      <FlexItem>{newValueString}</FlexItem>
    </Flex>
  );
};

type NodeColumnProps = {
  locationName: string | null;
  locationId: string | null;
  parentId: string | null;
  fullPath: string | null;
};

const NodeColumn = ({
  locationName,
  locationId,
  parentId,
  fullPath
}: NodeColumnProps) => {
  const { tenantId } = useContext(TenantContext);
  const titleLink =
    locationId == null ? '' : getSignalsUrl(tenantId, locationId);
  const subtitleLink =
    parentId == null ? '' : getSignalsUrl(tenantId, parentId);

  return (
    <TableColumn
      title={locationName}
      subtitle={fullPath}
      titleLink={titleLink}
      subtitleLink={subtitleLink}
    />
  );
};

type ActivityLogResponseModelWithLocationInfo = ActivityLogResponseModel & {
  locationName: string;
  fullPath: string;
  parentId: string;
};

const LogView = ({
  className,
  nodeId,
  tableClassName,
  activityLogTypes,
  onSortChange,
  currentPage = 0,
  orderBy,
  sortDirection,
  searchString,
  selectedSignalIds,
  onPageChange
}: LogViewProps) => {
  const signalUnitTypesMap = useOperatorSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const columns: DataTableColumnProps<ActivityLogResponseModelWithLocationInfo>[] =
    useMemo(
      () => [
        {
          label: T.common.date,
          dataKey: 'created',
          flexGrow: 0,
          minWidth: 180,
          dataFormatter: (created: string, row) => {
            return (
              <TableColumn
                title={moment
                  .utc(created)
                  .local()
                  .format(getDefaultDateTimeFormat(TimeFormats.LONG_TIME))}
                subtitle={row.username || <em>{T.logview.user.unknown}</em>}
              />
            );
          }
        },
        {
          label: T.logview.columns.location,
          dataKey: 'locationName',
          flexGrow: 1,
          minWidth: 180,
          dataFormatter: (locationName: string, row) => {
            return (
              <NodeColumn
                fullPath={row.fullPath}
                parentId={row.parentId}
                locationName={locationName}
                locationId={row.locationId}
              />
            );
          }
        },
        {
          label: T.common.message,
          dataKey: 'message',
          minWidth: 100
        },
        {
          label: T.logview.columns.logtype,
          dataKey: 'activityLogType',
          minWidth: 50,
          dataFormatter: (value: string) =>
            ActivityLogTypeTranslations[value] ?? value
        },
        {
          label: T.alarms.columns.signal,
          dataKey: 'signalName',
          minWidth: 30,
          dataFormatter: (name: string, row: ActivityLogResponseModel) => {
            return (
              <TableColumn title={name} subtitle={row.signalDescription} />
            );
          }
        },
        {
          label: T.logview.columns.valuechange,
          dataKey: 'oldValue',
          minWidth: 30,
          dataFormatter: (oldValue: number, row: ActivityLogResponseModel) =>
            formatValue(oldValue, row, signalUnitTypesMap)
        }
      ],
      [signalUnitTypesMap]
    );

  const additionalParams = useMemo(
    () => ({ SignalIds: selectedSignalIds }),
    [selectedSignalIds]
  );
  const api = useMemo(
    () =>
      selectedSignalIds
        ? APIGen.ActivityLog.get
        : APIGen.ActivityLog.getRecursive,
    [selectedSignalIds]
  );

  const { isLoading, error, data } = api.useQuery(
    {
      LocationId: nodeId.startsWith(ROOT_NODE_ID) ? undefined : nodeId,
      ActivityLogTypes: activityLogTypes,
      Page: currentPage + 1,
      SortColumn: orderBy,
      SortOrder: sortDirection,
      SearchPhrase: searchString,
      Grid: GridType.Generic, // TODO: New domain model: Implement this
      PageSize: 15,
      ...additionalParams
    },
    {
      placeholderData: keepPreviousData
    }
  );

  const nodeIds = useMemo(() => {
    return _.uniq(data?.activityLogs.map((log) => log.locationId) ?? []);
  }, [data?.activityLogs]);

  const { nodes: referencedNodes } = useNodesEx(nodeIds);

  const tableData = useMemo(() => {
    return data?.activityLogs.map((log) => {
      const location = referencedNodes.nodes.find(
        (node) => node.nodeId === log.locationId
      );

      const parent =
        location?.parentId != null
          ? referencedNodes.parents.find(
              (otherParent) => otherParent.nodeId === location?.parentId
            )
          : null;

      const fullPath = getFullPathNameV2(location, referencedNodes.parents);

      if (fullPath.length > 0) {
        // Remove last item from fullPath as it is the location itself
        fullPath.pop();
      }

      return {
        ...log,
        locationName: location?.name,
        parentName: parent?.name,
        parentId: parent?.parentId,
        fullPath: fullPath.join(' > ')
      };
    });
  }, [data?.activityLogs, referencedNodes.nodes, referencedNodes.parents]);

  return (
    <div className={className}>
      <DataTable<ActivityLogResponseModelWithLocationInfo>
        data={tableData}
        columns={columns}
        isLoading={isLoading}
        hasError={error != null}
        className={tableClassName}
        onSortChange={onSortChange}
        sortBy={orderBy}
        sortDirection={sortDirection}
        noDataText={T.logview.nodatatext}
        useAllAvailableHeight
      />
      <PagingFooter
        totalPages={data?.totalPages ?? 0}
        page={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default typedMemo(LogView);
