import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import APIGen, {
  NodeV2ResponseModel,
  NodePropertyResponseModel,
  NodePropertyValueResponseModel
} from 'ecto-common/lib/API/APIGen';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import SegmentedContentView from 'ecto-common/lib/SegmentControl/SegmentedContentView';
import { useParams } from 'react-router-dom';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { getNodesV2Url } from 'js/utils/routeConstants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import Icons from 'ecto-common/lib/Icons/Icons';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import UUID from 'uuidjs';
import {
  keepPreviousData,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import TextInput from 'ecto-common/lib/TextInput/TextInput';

import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import NodeSearchTable from 'ecto-common/lib/Page/NodeSearchTable';
import { Y } from 'ecto-common/lib/lang/NodeLocalization';
import { BatchedGetNodesQueryKey } from 'ecto-common/lib/hooks/useCurrentNode';

type NodesV2PageProps = {
  onTitleChanged: (newTitle: string[]) => void;
};

const NodesSearch = () => {
  const [nodeDetailId, setNodeDetailId] = useSearchParamState('node-id', null);

  const traitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();

  const [editNode, setEditNode] = useState<NodeV2ResponseModel | null>(null);

  const nodeSpecificQuery = APIGen.NodesV2.getNodesByIds.useQuery(
    {
      nodeIds: [nodeDetailId]
    },
    {
      enabled: !!nodeDetailId
    }
  );

  const getNodeTraitsForNodeQuery = APIGen.NodesV2.getNodeTraitsByIds.useQuery(
    {
      traitIds: editNode?.nodeTraitIds
    },
    {
      enabled: !!editNode?.nodeTraitIds,
      placeholderData: keepPreviousData
    }
  );

  const nodePropertiesQuery = APIGen.NodesV2.getNodeProperties.useQuery({});

  const nodePropertyValuesQuery = APIGen.NodesV2.getNodePropertyValues.useQuery(
    {
      nodeId: editNode?.nodeId
    },
    {
      enabled: !!editNode
    }
  );

  const [nodePropertyValues, setNodePropertyValues] = useState<
    Record<string, string>
  >({});

  const allPropertyIds = useMemo(() => {
    return _.flatMap(
      getNodeTraitsForNodeQuery.data,
      (trait) => trait.propertyIds
    );
  }, [getNodeTraitsForNodeQuery.data]);

  const allProperties = useMemo(() => {
    return allPropertyIds.map((x) =>
      nodePropertiesQuery.data.find((y) => y.id === x)
    );
  }, [allPropertyIds, nodePropertiesQuery.data]);

  const propertyColumns: DataTableColumnProps<NodePropertyResponseModel>[] = [
    {
      dataKey: 'name',
      label: 'Property'
    },
    {
      dataKey: 'id',
      label: 'Value',
      dataFormatter: (id: string) => {
        const value =
          nodePropertyValues[id] ??
          nodePropertyValuesQuery.data?.find((x) => x.nodePropertyId === id)
            ?.value;
        return (
          <TextInput
            value={value}
            onChange={(e) => {
              setNodePropertyValues((oldValues) => {
                return {
                  ...oldValues,
                  [id]: e.target.value
                };
              });
            }}
          />
        );
      }
    }
  ];

  if (nodeSpecificQuery.data?.nodes?.length === 1 && editNode == null) {
    setEditNode(_.cloneDeep(nodeSpecificQuery.data?.nodes?.[0]));
  }

  const onClickRow = (node: NodeV2ResponseModel) => {
    setNodeDetailId(node.nodeId);
  };

  const nodeModels = useMemo<ModelDefinition<NodeV2ResponseModel>[]>(() => {
    return [
      {
        key: (input) => input.name,
        label: T.common.name,
        modelType: ModelType.TEXT
      },
      {
        key: (input) => input.street,
        label: 'Street',
        modelType: ModelType.TEXT
      },
      {
        key: (input) => input.nodeTraitIds,
        label: Y.nodes.nodetraits,
        modelType: ModelType.OPTIONS,
        isMultiOption: true,
        options: traitsQuery.data?.map((trait) => ({
          value: trait.id,
          label: trait.name
        }))
      }
    ];
  }, [traitsQuery.data]);

  const { contextSettings } = useContext(TenantContext);

  const queryClient = useQueryClient();

  const saveNodeAndProperties = useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.listNodeTraits.path(contextSettings)
      });

      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.getNodesByIds.path(contextSettings)
      });

      queryClient.invalidateQueries({
        queryKey: [BatchedGetNodesQueryKey]
      });

      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.getNodePropertyValues.path(contextSettings)
      });
    },
    mutationFn: async ({
      properties,
      node
    }: {
      properties: NodePropertyValueResponseModel[];
      node: NodeV2ResponseModel;
    }) => {
      await APIGen.NodesV2.addOrUpdateNodes.promise(
        contextSettings,
        { nodes: [node] },
        null
      );
      await APIGen.NodesV2.addOrUpdateNodePropertyValues.promise(
        contextSettings,
        {
          nodeId: node.nodeId,
          propertyValues: properties
        },
        null
      );
    }
  });

  const saveNode = useCallback(() => {
    const valueDtos: NodePropertyValueResponseModel[] = _.compact(
      _.map(allPropertyIds, (propertyId) => {
        const existingValue = _.find(
          nodePropertyValuesQuery.data,
          (x) => x.nodePropertyId === propertyId
        );

        const value = nodePropertyValues[propertyId] ?? existingValue.value;

        if (isNullOrWhitespace(value)) {
          return null;
        }

        if (existingValue) {
          return {
            ...existingValue,
            value
          };
        }

        return {
          id: UUID.generate(),
          nodeId: editNode.nodeId,
          nodePropertyId: propertyId,
          value
        };
      })
    );

    saveNodeAndProperties.mutate({
      properties: valueDtos,
      node: editNode
    });
  }, [
    allPropertyIds,
    editNode,
    nodePropertyValues,
    nodePropertyValuesQuery.data,
    saveNodeAndProperties
  ]);

  return (
    <>
      <NodeSearchTable onClickNode={onClickRow} />

      <ActionModal
        isOpen={!!nodeDetailId}
        onModalClose={() => {
          setNodePropertyValues({});
          setEditNode(null);
          setNodeDetailId(null);
        }}
        title="Node"
        cancelText={T.common.done}
        actionText={
          <>
            <Icons.Save /> {T.common.save}{' '}
          </>
        }
        onConfirmClick={saveNode}
        headerIcon={Icons.Edit}
        isLoading={
          nodeSpecificQuery.isLoading || saveNodeAndProperties.isPending
        }
      >
        <ModelForm
          input={editNode}
          setInput={setEditNode}
          models={nodeModels}
          disabled={editNode == null}
        />
        {nodePropertyValues && allProperties.length > 0 && (
          <DataTable data={allProperties} columns={propertyColumns} />
        )}
      </ActionModal>
    </>
  );
};

const NodesV2Page = ({ onTitleChanged }: NodesV2PageProps) => {
  _.noop(onTitleChanged);

  const params = useParams<NodeParams>();
  useEffect(() => {
    document.title = 'Nodes V2';
  }, []);
  const { tenantId } = useContext(TenantContext);

  const sections = useMemo(() => {
    return [
      {
        title: 'Nodes',
        key: 'nodes',
        view: <NodesSearch />,
        icon: <Icons.Site />,
        url: getNodesV2Url(tenantId, params.nodeId, 'nodes')
      }
    ];
  }, [params.nodeId, tenantId]);

  let startIndex = _.findIndex(sections, { key: params.subPage });

  if (startIndex === -1) {
    startIndex = 0;
  }

  return (
    <ToolbarContentPage title="Nodes V2" wrapContent showLocationPicker={false}>
      <SegmentedContentView sections={sections} index={startIndex} />
    </ToolbarContentPage>
  );
};

export default React.memo(NodesV2Page);
