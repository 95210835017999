import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  useContext
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';

import {
  getSelectedSignalCollection,
  getSelectedSignals
} from 'js/containers/GraphComponents/GraphsUtils';
import { DEFAULT_GRAPH_SETTINGS } from 'js/modules/signalCollections/types';
import { SignalCollectionActions } from 'js/modules/signalCollections/signalCollections';
import GraphToolbarItems from 'js/containers/GraphComponents/GraphToolbarItems';
import GraphChart from 'js/containers/GraphComponents/GraphChart';
import EditGraphDialog from 'js/components/OperatorChart/EditGraphDialog';
import GraphConfirmSaveDialog from 'js/containers/GraphComponents/GraphConfirmSaveDialog';
import GraphConfirmDeleteDialog from 'js/containers/GraphComponents/GraphConfirmDeleteDialog';
import ExportDialog, {
  ExportGraphImageOptions
} from 'js/components/OperatorChart/ExportDialog';
import styles from 'js/containers/GraphEditor.module.css';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import {
  useOperatorSelector,
  useOperatorDispatch
} from 'js/reducers/storeOperator';
import {
  GraphCollectionType,
  GraphSettingsType,
  SeriesInterval
} from 'ecto-common/lib/types/EctoCommonTypes';
import GraphMinMaxDialog from 'js/components/OperatorChart/GraphMinMaxDialog';
import {
  convertTelemetryToSeries,
  ChartSignal,
  getSignalCollectionsPromise,
  TelemetryAndAggregationResponseModel,
  ChartSignalSettingsType,
  ChartZoomSettings
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import { useQuery } from '@tanstack/react-query';
import APIGen, {
  AggregationType,
  SamplingInterval
} from 'ecto-common/lib/API/APIGen';
import {
  SeriesIntervalToRange,
  TelemetryZoomRange
} from 'js/modules/signalCollections/signalCollections';

import moment from 'moment';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import dimensions from 'ecto-common/lib/styles/dimensions';
import useInterval from 'ecto-common/lib/hooks/useInterval';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { fitPointsInView } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { useResizeDetector } from 'react-resize-detector';
import localStore from 'store';
import { useNodesEx } from 'ecto-common/lib/hooks/useCurrentNode';

const ZoomSettingsKey = 'GraphZoomSettings';

function getZoomSettingsFromStore(): ChartZoomSettings {
  return _.merge(
    {
      autoUpdate: true,
      includeForecastedValues: false
    },
    localStore.get(ZoomSettingsKey) ?? {}
  );
}

type SettingsAndSignalId = {
  settings: ChartSignalSettingsType;
  signalId: string;
};

const getEditGraphSettings = (
  signalCollection: GraphCollectionType
): GraphSettingsType => {
  return {
    ...(signalCollection?.settings ?? DEFAULT_GRAPH_SETTINGS),
    name: signalCollection?.name
  };
};

const AUTO_UPDATE_INTERVAL_MS = 1000 * 60;

const _zoomRangeFromSparseTelemetry = (
  sparseTelemetry: TelemetryAndAggregationResponseModel[]
): TelemetryZoomRange => {
  //
  // Get .data from all signals and find max/min date from them
  //
  const maxMin = sparseTelemetry.reduce(
    ({ max, min }, signalData) => {
      // we assume date is in increasing order, thus first = min date, last = max date.
      const first = _.first(signalData.signals);
      const last = _.last(signalData.signals);
      const dataMin =
        first?.time != null ? moment.utc(first.time).valueOf() : null;
      const dataMax =
        last?.time != null ? moment.utc(last.time).valueOf() : null;

      const result = { max, min };
      if (result.max == null) {
        result.max = dataMax;
      } else {
        result.max =
          dataMax != null ? Math.max(result.max, dataMax) : result.max;
      }

      if (result.min == null) {
        result.min = dataMin;
      } else {
        result.min =
          dataMin != null ? Math.min(result.min, dataMin) : result.min;
      }
      return result;
    },
    {} as { min?: number; max?: number }
  );

  const now = new Date().getTime();
  const dateFrom = maxMin.min ?? now - threeYearsInMilliseconds;
  const dateTo = maxMin.max ?? now;

  return { dateFrom, dateTo };
};

export const createSignalFetchSettings = (
  signalCollection: ChartSignal[],
  samplingInterval: SamplingInterval,
  aggregation: AggregationType
): SettingsAndSignalId[] => {
  return signalCollection.map((chartSignal) => ({
    settings: {
      aggregation:
        chartSignal.settings?.aggregation ??
        aggregation ??
        AggregationType.None,
      samplingInterval:
        chartSignal.settings?.samplingInterval ??
        samplingInterval ??
        SamplingInterval.Raw
    },
    signalId: chartSignal.item.signalId
  }));
};

const fetchSignalDataPromise = (
  contextSettings: ApiContextSettings,
  signalsToFetch: ChartSignal[],
  dateFrom: number,
  dateTo: number,
  samplingInterval: SamplingInterval,
  aggregation: AggregationType,
  points: number,
  optimizeLargeSets: boolean
): Promise<TelemetryAndAggregationResponseModel[]> => {
  const settingsAndIds: SettingsAndSignalId[] = createSignalFetchSettings(
    signalsToFetch,
    samplingInterval,
    aggregation
  );

  const groupedSignalsBySettingsDict = _.groupBy(
    settingsAndIds,
    (item) => item.settings.samplingInterval + ',' + item.settings.aggregation
  );
  const groupedSignalsBySettings = Object.values(groupedSignalsBySettingsDict);

  const promises = _.map(groupedSignalsBySettings, (signalGroup) => {
    const sharedSettings = _.head(signalGroup).settings;
    const {
      aggregation: groupAggregation,
      samplingInterval: groupSamplingInterval
    } = sharedSettings;
    const groupSignalIds = signalGroup.map((s) => s.signalId);

    if (
      groupSamplingInterval === SamplingInterval.Raw ||
      groupAggregation === AggregationType.None ||
      (optimizeLargeSets &&
        (samplingInterval === SamplingInterval.Minute ||
          samplingInterval === SamplingInterval.Hour))
    ) {
      return APIGen.Signals.getSignalValues.promise(
        contextSettings,
        {
          SignalIds: groupSignalIds,
          StartDate: new Date(dateFrom).toISOString(),
          EndDate: new Date(dateTo).toISOString(),
          Points: Math.ceil(points)
        },
        null
      );
    }

    return APIGen.Signals.getSignalValuesByTimeRange.promise(
      contextSettings,
      {
        SignalIds: groupSignalIds,
        StartDate: new Date(dateFrom).toISOString(),
        EndDate: new Date(dateTo).toISOString(),
        SamplingInterval: groupSamplingInterval,
        Aggregation: groupAggregation
      },
      null
    );
  });

  return Promise.all(promises).then((response) => {
    const expandedResponses: TelemetryAndAggregationResponseModel[][] =
      response.map((items, idx) => {
        const groupedSetting = _.head(groupedSignalsBySettings[idx]).settings;

        return items.map((item) => ({
          ...item,
          ...groupedSetting
        }));
      });

    return _.flatMap(expandedResponses);
  });
};

const threeYearsInMilliseconds = 3 * 365 * 24 * 60 * 60 * 1000;

const fetchSparseSignalDataPromise = (
  contextSettings: ApiContextSettings,
  signalsToFetch: ChartSignal[],
  samplingInterval: SamplingInterval,
  aggregation: AggregationType,
  points: number,
  optimizeLargeSets: boolean,
  dateFrom: number,
  dateTo: number
): Promise<TelemetryAndAggregationResponseModel[]> => {
  if (!signalsToFetch || signalsToFetch.length === 0) {
    return Promise.resolve([]);
  }

  return fetchSignalDataPromise(
    contextSettings,
    signalsToFetch,
    dateFrom,
    dateTo,
    samplingInterval,
    aggregation,
    points,
    optimizeLargeSets
  );
};

const fetchSpecificSignalDataPromise = (
  contextSettings: ApiContextSettings,
  signalsToFetch: ChartSignal[],
  zoomRange: TelemetryZoomRange,
  samplingInterval: SamplingInterval,
  aggregation: AggregationType,
  points: number,
  optimizeLargeSets: boolean
): Promise<TelemetryAndAggregationResponseModel[]> => {
  if (signalsToFetch.length === 0) {
    return Promise.resolve([]);
  }

  return fetchSignalDataPromise(
    contextSettings,
    signalsToFetch,
    zoomRange.dateFrom,
    zoomRange.dateTo,
    samplingInterval,
    aggregation,
    points,
    optimizeLargeSets
  );
};

type GraphEditorProps = {
  customZoomRange: TelemetryZoomRange | null;
  setCustomZoomRange: (range: TelemetryZoomRange | null) => void;
  seriesInterval: SeriesInterval;
  setSeriesIntervalAndClearCustomZoomRange: (interval: SeriesInterval) => void;
  initialCollectionId?: string;
};

const GraphEditor = ({
  customZoomRange,
  setCustomZoomRange,
  seriesInterval: _seriesInterval,
  initialCollectionId = null,
  setSeriesIntervalAndClearCustomZoomRange: setSeriesInterval
}: GraphEditorProps) => {
  const isAdmin = useOperatorSelector((state) => state.general.isAdmin);
  const dispatch = useOperatorDispatch();
  const { width, ref } = useResizeDetector();

  const { contextSettings } = useContext(TenantContext);
  const getCollectionsQuery = useQuery({
    queryKey: ['graph-user-collections'],

    queryFn: ({ signal }) => {
      return getSignalCollectionsPromise(contextSettings, signal, isAdmin);
    },

    staleTime: Infinity,
    refetchOnWindowFocus: false
  });

  const lastUserCollections = useRef<GraphCollectionType[]>(null);

  useEffect(() => {
    if (
      lastUserCollections.current !== getCollectionsQuery.data &&
      getCollectionsQuery.data
    ) {
      lastUserCollections.current = getCollectionsQuery.data;
      dispatch(
        SignalCollectionActions.setUserSignalCollections(
          getCollectionsQuery.data,
          initialCollectionId
        )
      );
    }
  }, [getCollectionsQuery.data, dispatch, initialCollectionId]);

  const selectedSignalCollection = useOperatorSelector((state) =>
    getSelectedSignalCollection(state.signalCollections)
  );

  const selectedSignals = useOperatorSelector((state) =>
    getSelectedSignals(state.signalCollections)
  );
  const canClearInterval = _seriesInterval != null || customZoomRange != null;
  const seriesInterval =
    _seriesInterval ??
    selectedSignalCollection?.settings?.seriesInterval ??
    SeriesInterval.DAY;

  const [showingSignalSelector, showSignalSelector, hideSignalSelector] =
    useSimpleDialogState();

  const pendingNewCollectionId = useOperatorSelector(
    (state) => state.signalCollections.pendingNewCollectionId
  );

  useEffect(() => {
    if (pendingNewCollectionId != null && !showingSignalSelector) {
      dispatch(
        SignalCollectionActions.confirmDeleteSignalCollection(
          contextSettings,
          pendingNewCollectionId
        )
      );
      dispatch(SignalCollectionActions.clearPendingNewCollectionId());
    }
  }, [
    contextSettings,
    dispatch,
    pendingNewCollectionId,
    showingSignalSelector
  ]);

  const [exportDialogIsOpen, onShowExportDialog, onHideExportDialog] =
    useSimpleDialogState(false);
  const [showMinMaxSettings, onShowMinMaxSettings, onHideMinMaxSettings] =
    useSimpleDialogState(false);

  const [dataAnalyticsEnabled, setDataAnalyticsEnabled] = useState(false);

  const currentGraphSettings = useMemo(
    () => getEditGraphSettings(selectedSignalCollection),
    [selectedSignalCollection]
  );
  const [editGraphSettings, setEditGraphSettings] =
    useState<GraphSettingsType>(currentGraphSettings);
  const currentSettings: GraphSettingsType =
    selectedSignalCollection?.settings ?? DEFAULT_GRAPH_SETTINGS;
  const [zoomSettings, setZoomSettings] = useState<ChartZoomSettings>(
    getZoomSettingsFromStore
  );

  useEffect(() => {
    localStore.set(ZoomSettingsKey, zoomSettings);
  }, [zoomSettings]);

  useEffect(() => {
    setEditGraphSettings(currentGraphSettings);
  }, [currentGraphSettings]);

  const onConfirmEditGraphDialog = useCallback(
    (_selectedSignals: ChartSignal[]) => {
      const { name, ...otherSettings } = editGraphSettings;
      dispatch(SignalCollectionActions.clearPendingNewCollectionId());
      dispatch(
        SignalCollectionActions.updateCollection(
          _selectedSignals,
          name,
          otherSettings
        )
      );
      hideSignalSelector();
    },
    [dispatch, editGraphSettings, hideSignalSelector]
  );

  const onCloseEditGraphDialog = useCallback(() => {
    if (pendingNewCollectionId == null) {
      setEditGraphSettings(getEditGraphSettings(selectedSignalCollection));
    }

    hideSignalSelector();
  }, [pendingNewCollectionId, hideSignalSelector, selectedSignalCollection]);

  const onConfirmMinMaxDialog = useCallback(
    (newGraphSettings: GraphSettingsType) => {
      const { name, ...otherSettings } = newGraphSettings;
      dispatch(
        SignalCollectionActions.setSignalCollectionNameAndSettings(
          name,
          otherSettings
        )
      );
      onHideMinMaxSettings();
    },
    [dispatch, onHideMinMaxSettings]
  );

  const onCloseMinMaxGraphDialog = useCallback(() => {
    setEditGraphSettings(getEditGraphSettings(selectedSignalCollection));
    onHideMinMaxSettings();
  }, [onHideMinMaxSettings, selectedSignalCollection]);

  let selectedXAxisScatterSignal = null;

  if (currentSettings.xAxisChartSignalId != null) {
    selectedXAxisScatterSignal = _.find(selectedSignals, [
      'chartSignalId',
      currentSettings.xAxisChartSignalId
    ]);
  }

  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );
  const chartAreaWidth =
    (width ?? 500) -
    dimensions.plainBoxPadding * 2 -
    dimensions.borderWidth * 2;
  const queryNumPoints = currentGraphSettings?.numPoints || chartAreaWidth;
  const { tenantId } = useContext(TenantContext);

  const chartRef = useRef(null);

  const lastValuesQuery = APIGen.Signals.getLastSignalValues.useQuery(
    {
      signalIds: selectedSignals.map((s) => s.item.signalId)
    },
    {
      enabled: selectedSignals.length > 0
    }
  );

  const sparseTelemetryQueryInput = useMemo(() => {
    if (lastValuesQuery.data == null) {
      return null;
    }

    const lastValueDates = lastValuesQuery.data
      .map((x) => x.signals && x.signals.length === 1 && x.signals[0].time)
      .filter((x) => x != null)
      .map((y) => new Date(y).getTime());

    let dateTo =
      (lastValueDates.length > 0 && Math.max(...lastValueDates)) ||
      new Date().getTime();
    dateTo += 1000 * 60; // To account for conversion errors

    // Current time minus three years

    return { dateFrom: dateTo - threeYearsInMilliseconds, dateTo };
  }, [lastValuesQuery.data]);

  const referencedNodeIds = useMemo(() => {
    return _.uniq(
      _.flatMap(selectedSignals, (signal) => signal.group.nodeIds ?? [])
    );
  }, [selectedSignals]);

  const nodeInfoQuery = useNodesEx(referencedNodeIds);

  const sparseTelemetryQuery = useQuery({
    queryKey: [
      'sparseTelemetry',
      tenantId,
      currentGraphSettings.samplingInterval,
      currentGraphSettings.aggregation,
      queryNumPoints,
      ...selectedSignals.map((s) => s.chartSignalId)
    ],

    queryFn: () => {
      return fetchSparseSignalDataPromise(
        contextSettings,
        selectedSignals,
        currentGraphSettings.samplingInterval,
        currentGraphSettings.aggregation,
        queryNumPoints,
        false,
        sparseTelemetryQueryInput.dateFrom,
        sparseTelemetryQueryInput.dateTo
      );
    },

    gcTime: 5000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: width != null && sparseTelemetryQueryInput != null
  });

  const sparseTelemetryQueryData = useMemo(() => {
    const data = sparseTelemetryQuery.data;
    if (data == null) {
      return null;
    }

    return {
      series: convertTelemetryToSeries(
        selectedSignals,
        signalTypesMap,
        signalUnitTypesMap,
        currentGraphSettings,
        data.map((dataEntry) => ({
          ...dataEntry,
          signals: fitPointsInView(dataEntry.signals, width)
        })),
        nodeInfoQuery.nodes.nodes,
        nodeInfoQuery.nodes.parents
      ),
      zoomRange: _zoomRangeFromSparseTelemetry(data)
    };
  }, [
    sparseTelemetryQuery.data,
    selectedSignals,
    signalTypesMap,
    signalUnitTypesMap,
    currentGraphSettings,
    nodeInfoQuery.nodes.nodes,
    nodeInfoQuery.nodes.parents,
    width
  ]);

  const [regenerateZoomRangeToUse, setRegenerateZoomRangeToUse] =
    useReloadTrigger();

  useInterval(
    useCallback(() => {
      if (
        seriesInterval !== SeriesInterval.ALL &&
        customZoomRange == null &&
        zoomSettings.autoUpdate
      ) {
        setRegenerateZoomRangeToUse();
      }
      // Include selectedSignals in dep to useInterval to re-init the timer each time we change selected signals
    }, [
      zoomSettings.autoUpdate,
      customZoomRange,
      seriesInterval,
      setRegenerateZoomRangeToUse
    ]),
    AUTO_UPDATE_INTERVAL_MS,
    false,
    selectedSignals
  );

  const sparseZoomRange = useMemo(() => {
    _.noop(regenerateZoomRangeToUse);
    _.noop(selectedSignals);
    if (!sparseTelemetryQueryData) {
      return null;
    }

    return {
      dateFrom: sparseTelemetryQueryData.zoomRange.dateFrom,
      dateTo: sparseTelemetryQueryData.zoomRange.dateTo,
      seriesInterval: SeriesInterval.ALL
    };
  }, [regenerateZoomRangeToUse, selectedSignals, sparseTelemetryQueryData]);

  const forecastedDateTo = zoomSettings.includeForecastedValues
    ? sparseTelemetryQueryInput?.dateTo
    : null;

  const intervalZoomRange = useMemo(() => {
    let now = new Date().getTime();

    if (zoomSettings.includeForecastedValues) {
      if (forecastedDateTo != null) {
        now = forecastedDateTo;
      } else {
        return null;
      }
    }

    _.noop(regenerateZoomRangeToUse);

    return {
      dateFrom: now - SeriesIntervalToRange(seriesInterval),
      dateTo: now,
      seriesInterval
    };
  }, [
    zoomSettings.includeForecastedValues,
    regenerateZoomRangeToUse,
    seriesInterval,
    forecastedDateTo
  ]);

  let zoomRangeToUse: TelemetryZoomRange;

  if (customZoomRange != null) {
    zoomRangeToUse = customZoomRange;
  } else if (seriesInterval === SeriesInterval.ALL) {
    zoomRangeToUse = sparseZoomRange;
  } else {
    zoomRangeToUse = intervalZoomRange;
  }

  const telemetryQuery = useQuery({
    queryKey: [
      'telemetry',
      tenantId,
      zoomRangeToUse,
      currentGraphSettings.samplingInterval,
      currentGraphSettings.aggregation,
      queryNumPoints,
      ...selectedSignals.map((s) => s.chartSignalId),
      createSignalFetchSettings(
        selectedSignals,
        currentGraphSettings.samplingInterval,
        currentGraphSettings.aggregation
      )
    ],

    queryFn: () => {
      return fetchSpecificSignalDataPromise(
        contextSettings,
        selectedSignals,
        zoomRangeToUse,
        currentGraphSettings.samplingInterval,
        currentGraphSettings.aggregation,
        queryNumPoints,
        false
      );
    },

    enabled: zoomRangeToUse != null && width != null,
    gcTime: 5000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const onExportGraphImage = useCallback(
    (options: ExportGraphImageOptions) => {
      try {
        chartRef.current.chart.exportChartLocal(options);
        onHideExportDialog();
      } catch (error) {
        toastStore.addErrorToast(T.graphs.exportdialog.failedtosavefile);
      }
    },
    [onHideExportDialog]
  );

  const _setSeriesInterval = useCallback(
    (newInterval: SeriesInterval) => {
      setSeriesInterval(newInterval);
    },
    [setSeriesInterval]
  );

  const telemetryQueryData = useMemo(() => {
    const data = telemetryQuery.data;
    if (data == null) {
      return null;
    }

    const isOverflowing = false;

    const safeResponse = _.map(data, (val) => {
      return {
        ...val,
        signals: val.signals
      };
    });

    return {
      series: convertTelemetryToSeries(
        selectedSignals,
        signalTypesMap,
        signalUnitTypesMap,
        currentGraphSettings,
        safeResponse,
        nodeInfoQuery.nodes.nodes,
        nodeInfoQuery.nodes.parents
      ),
      isOverflowing
    };
  }, [
    telemetryQuery.data,
    selectedSignals,
    signalTypesMap,
    signalUnitTypesMap,
    currentGraphSettings,
    nodeInfoQuery.nodes.nodes,
    nodeInfoQuery.nodes.parents
  ]);

  const telemetrySeries = telemetryQueryData?.series;
  const sparseTelemetrySeries = sparseTelemetryQueryData?.series;
  const hasPointsOverflow = telemetryQueryData?.isOverflowing;
  const signalsHasError =
    telemetryQuery.error != null || sparseTelemetryQuery.error != null;
  const signalsIsLoading =
    telemetryQuery.isLoading ||
    (zoomRangeToUse == null && lastValuesQuery.isLoading);

  if (getCollectionsQuery.isLoading) {
    return <LoadingContainer isLoading />;
  } else if (getCollectionsQuery.error != null) {
    return (
      <PlainBox className={styles.withTopMargin}>
        <ErrorNotice>{T.graphs.failedtoload}</ErrorNotice>
      </PlainBox>
    );
  }

  return (
    <>
      {zoomRangeToUse && (
        <ExportDialog
          zoomRange={zoomRangeToUse}
          isOpen={exportDialogIsOpen}
          selectedSignals={selectedSignals}
          onModalClose={onHideExportDialog}
          graphSettings={editGraphSettings}
          onExportGraphImage={onExportGraphImage}
        />
      )}

      <Toolbar isPageHeadingToolbar>
        <GraphToolbarItems
          graphType={currentSettings.type}
          setSeriesInterval={_setSeriesInterval}
          canClearInterval={canClearInterval}
          showExport={onShowExportDialog}
          showMinMaxSettings={onShowMinMaxSettings}
          zoomSettings={zoomSettings}
          setZoomSettings={setZoomSettings}
          zoomRange={zoomRangeToUse}
          setCustomZoomRange={setCustomZoomRange}
          showSignalSelector={showSignalSelector}
          dataAnalyticsEnabled={dataAnalyticsEnabled}
          setDataAnalyticsEnabled={setDataAnalyticsEnabled}
        />
      </Toolbar>

      <PlainBox className={classNames(styles.detailsPage)} ref={ref}>
        <GraphChart
          enableDataAnalytics={dataAnalyticsEnabled}
          graphType={currentSettings.type}
          selectedXAxisScatterSignal={selectedXAxisScatterSignal}
          ref={chartRef}
          graphSettings={currentGraphSettings}
          hasError={signalsHasError}
          isLoading={signalsIsLoading}
          telemetrySeries={telemetrySeries}
          sparseTelemetrySeries={sparseTelemetrySeries}
          hasPointsOverflow={hasPointsOverflow}
          setCustomZoomRange={setCustomZoomRange}
          zoomRange={zoomRangeToUse}
        />

        <EditGraphDialog
          isOpen={showingSignalSelector}
          selectedSignals={selectedSignals}
          onConfirmClick={onConfirmEditGraphDialog}
          onModalClose={onCloseEditGraphDialog}
          graphSettings={editGraphSettings}
          setGraphSettings={setEditGraphSettings}
          isEditingNewCollection={pendingNewCollectionId != null}
        />

        <GraphConfirmSaveDialog />

        <GraphConfirmDeleteDialog />

        <GraphMinMaxDialog
          onConfirmSettings={onConfirmMinMaxDialog}
          isOpen={showMinMaxSettings}
          selectedSignals={selectedSignals}
          onModalClose={onCloseMinMaxGraphDialog}
          graphSettings={editGraphSettings}
          setGraphSettings={setEditGraphSettings}
        />
      </PlainBox>
    </>
  );
};

export default React.memo(GraphEditor);
