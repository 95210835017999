import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import Button from 'ecto-common/lib/Button/Button';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import Notice from 'ecto-common/lib/Notice/Notice';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import T from 'ecto-common/lib/lang/Language';

import AdjustableChart, {
  AdjustableChartCurve,
  AdjustableChartSeries,
  AdjustableChartSignal,
  HighchartsOptionsWithAdjustableSettings
} from 'ecto-common/lib/AdjustableChart/AdjustableChart';
import styles from 'js/components/EMPTools/RemoteOptimisation/RemoteOptimisationSettings.module.css';
import { formatNumberUnit, Unit } from 'ecto-common/lib/utils/stringUtils';
import { yAxisFormatter } from 'ecto-common/lib/SignalSelector/ChartUtils';
import APIGen, {
  SignalGroupLiteResponseModel
} from 'ecto-common/lib/API/APIGen';
import Switch from 'ecto-common/lib/Switch/Switch';

const CHART_WIDTH = 500;
const CHART_HEIGHT = 500;
const PADDING = 30;

const MIN_VALUE_X = -16;
const MAX_VALUE_X = 26;
const MIN_VALUE_Y = -10;
const MAX_VALUE_Y = 10;

const chartSettings: HighchartsOptionsWithAdjustableSettings = {
  tooltip: {
    formatter() {
      return `<b>${T.remoteopt.outsidetemplabel}</b> ${formatNumberUnit(_.isNumber(this.x) ? this.x : _.parseInt(this.x), Unit.CELCIUS)}
    <br />
    <b>${T.remoteopt.tempoffsetlabel}</b> ${formatNumberUnit(this.y, Unit.CELCIUS)}`;
    }
  },
  xAxis: {
    title: {
      text: Unit.CELCIUS,
      rotation: 0,
      offset: 0,
      align: 'low',
      x: -20,
      y: -8
    },
    offset:
      -(
        (CHART_HEIGHT - PADDING * 2) /
        (Math.abs(MIN_VALUE_Y) + Math.abs(MAX_VALUE_Y))
      ) * Math.abs(MIN_VALUE_Y),
    min: MIN_VALUE_X,
    max: MAX_VALUE_X
  },
  yAxis: {
    title: {
      text: Unit.CELCIUS,
      rotation: 0,
      offset: 0,
      align: 'high',
      y: -10,
      x: 5
    },
    labels: {
      formatter: yAxisFormatter
    },
    offset:
      -(
        (CHART_WIDTH - PADDING * 2) /
        (Math.abs(MIN_VALUE_X) + Math.abs(MAX_VALUE_X))
      ) * Math.abs(MIN_VALUE_X),
    min: MIN_VALUE_Y,
    max: MAX_VALUE_Y
  },
  chart: {
    marginTop: PADDING,
    marginBottom: PADDING,
    marginLeft: PADDING,
    marginRight: PADDING,
    width: CHART_WIDTH,
    height: CHART_HEIGHT
  },
  dragDrop: {
    dragMinY: MIN_VALUE_Y,
    dragMaxY: MAX_VALUE_Y,
    dragMinX: MIN_VALUE_X,
    dragMaxX: MAX_VALUE_X
  }
};

const EMPTY_OBJECT = {};

interface RemoteOptimisationSettingsProps {
  selectedOptimisation: SignalGroupLiteResponseModel;
}

const RemoteOptimisationSettings = ({
  selectedOptimisation
}: RemoteOptimisationSettingsProps) => {
  const [points, setPoints] = useState<AdjustableChartSignal[]>([]);

  const getEquipmentQuery =
    APIGen.RemoteOptimisation.getLinearOptimisationByProvider.useQuery(
      {
        ProviderIds: [selectedOptimisation?.signalGroupId]
      },
      {
        enabled: !!selectedOptimisation?.signalGroupId
      }
    );

  const setOptimizationMutation =
    APIGen.RemoteOptimisation.setLinearOptimisationSignals.useMutation({
      onSuccess: () => {
        getEquipmentQuery.refetch();
      },
      onError: () => {
        toastStore.addErrorToast(
          T.format(
            T.remoteopt.action.enable.failure,
            selectedOptimisation?.name
          )
        );
      }
    });

  const result = getEquipmentQuery.data?.[0];

  const signalProviderId = result?.signalProviderId;

  const onChartDrop = useCallback(
    (_points: AdjustableChartSignal[]) => setPoints([..._points]),
    []
  );

  const isLoading =
    setOptimizationMutation.isPending || getEquipmentQuery.isLoading;

  const isEnabled = !!result?.enableSignal?.value && !isLoading;

  const hasData =
    (!getEquipmentQuery.error != null && result != null) ||
    signalProviderId != null;

  const onSaveClick = useCallback(() => {
    const item = [
      {
        signalProviderId,
        signalValues: _.map(points, (p) => {
          return {
            value: p.value,
            signalId: p.signalId
          };
        })
      }
    ];

    setOptimizationMutation.mutate(item);
  }, [signalProviderId, points, setOptimizationMutation]);

  const onEnableChange = useCallback(() => {
    const item = [
      {
        signalProviderId,
        signalValues: [
          {
            signalId: result?.enableSignal?.signalId,
            value: !isEnabled ? 1 : 0
          }
        ]
      }
    ];
    setOptimizationMutation.mutate(item);
  }, [
    signalProviderId,
    result?.enableSignal?.signalId,
    isEnabled,
    setOptimizationMutation
  ]);

  const series: AdjustableChartCurve[] = useMemo(
    () =>
      // add result and append isWritableX/Y: true since this curve should be adjustable
      // TODO: This cast is not ideal, verify that this cast is working as intended
      [
        {
          color: null,
          name: null,
          isWritableX: true,
          isWritableY: true,
          series: result?.remoteOptimisationPoints as AdjustableChartSeries[]
        }
      ],
    [result?.remoteOptimisationPoints]
  );

  return (
    <LoadingContainer className={styles.loadingContainer} isLoading={isLoading}>
      {getEquipmentQuery.error != null && (
        <ErrorNotice>{T.remoteopt.fetch.error}</ErrorNotice>
      )}

      {!hasData && !isLoading && <Notice>{T.remoteopt.nodata}</Notice>}

      {hasData && (
        <div>
          <label className={styles.toggle}>
            <span>{T.remoteopt.info.title}</span>

            <Switch isOn={isEnabled} onClick={onEnableChange} />
          </label>

          <div
            className={classNames(styles.chart, !isEnabled && styles.disabled)}
          >
            <AdjustableChart
              onChartDrop={onChartDrop}
              series={series}
              settings={chartSettings}
              domProps={EMPTY_OBJECT}
            />

            <Button
              className={styles.button}
              disabled={!isEnabled}
              onClick={onSaveClick}
            >
              {T.remoteopt.button.save.title}
            </Button>
          </div>
        </div>
      )}
    </LoadingContainer>
  );
};

export default RemoteOptimisationSettings;
